
import { defineComponent, onMounted, ref } from 'vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { Actions } from '@/store/enums/StoreEnums';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';

export default defineComponent({
	name: 'sign-in',
	components: {
		Field,
		Form,
		ErrorMessage
	},
	setup() {
		const store = useStore();
		const router = useRouter();

		const submitButton = ref<HTMLButtonElement | null>(null);

		//Create form validation object
		const login = Yup.object().shape({
			username: Yup.string().email().required().label('Email'),
			password: Yup.string().min(4).required().label('Password')
		});

		onMounted(() => {
			//check if current user is authenticated
			if (store.getters.isUserAuthenticated) {
				router.push({ name: 'dashboard' });
			}
		});

		//Form submit function
		const onSubmitLogin = async values => {
			// Clear existing errors
			store.dispatch(Actions.LOGOUT);

			if (submitButton.value) {
				// eslint-disable-next-line
				submitButton.value!.disabled = true;
				// Activate indicator
				submitButton.value.setAttribute('data-kt-indicator', 'on');
			}

			// Send login request
			await store.dispatch(Actions.LOGIN, values);

			const error = store.getters.getErrors;

			if (!error) {
				router.push({ name: 'dashboard' });
			} else {
				Swal.fire({
					text: error,
					icon: 'error',
					buttonsStyling: false,
					confirmButtonText: 'Try again!',
					customClass: {
						confirmButton: 'btn fw-bold btn-light-danger'
					}
				});
			}

			//Deactivate indicator
			submitButton.value?.removeAttribute('data-kt-indicator');
			// eslint-disable-next-line
			submitButton.value!.disabled = false;
		};

		return {
			onSubmitLogin,
			login,
			submitButton
		};
	}
});
